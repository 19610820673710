const PageNotFound = () => {
  return (
    <div className="page-column">
      <div
        className="header"
        style={{ marginTop: "50px", textAlign: "center" }}
      >
        404 Error - Page Not Found
      </div>
    </div>
  );
};

export default PageNotFound;
